<template>
  <b-badge :variant="variant">
    {{ status | capitalize }}
</b-badge>
</template>

<script>
export default {
  name: 'CandleStatusBadge',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    variant() {
      return this.variants[this.status];
    },
  },
  data() {
    return {
      variants: {
        testing: 'warning',
        production: 'success',
        discarded: 'danger',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
