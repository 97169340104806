import { render, staticRenderFns } from "./CandleStatusBadge.vue?vue&type=template&id=725253df&scoped=true"
import script from "./CandleStatusBadge.vue?vue&type=script&lang=js"
export * from "./CandleStatusBadge.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725253df",
  null
  
)

export default component.exports